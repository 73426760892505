// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


h2.visibilityCheck, h3.visibilityCheck, h4.visibilityCheck, h5.visibilityCheck, p.visibilityCheck {
    transform: translateY(100px);
    transition: 1s;
    opacity: 0;
    &.isVisible {
        transform: translateY(0);
        transition: 1s;
        opacity: 1;
    }
}

img.visibilityCheck {
    transition: 1s;
    opacity: 0;
    &.isVisible {
        transition: 1s;
        opacity: 1;
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';
//@import 'sections/section-temoignages';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';


a.btn-link {
    background: $color-1;
    width: 228px;
    height: 60px;
    margin-top: 50px;
    border-radius: 10px;
    transition: 0.3s;
    h5 {
        font-weight: normal;
        font-size: 16px;
        color: #EEF4FA;
        letter-spacing: 3.2px;
        text-transform: uppercase;
    }
    &:hover {
        background: #84BACA;
        transition: 0.3s;
    }
}





/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-burst {
    .image-side {
        position: relative;
        .logo {
            position: absolute;
            top: 0;
        }
        &.left {
            .logo {
                left: 10%;
            }
        }
        &.right {
            .logo {
                right: 10%;
            }
        }
        .overlay {
            position: absolute;
            width: 100%;
            height: 140px;
            bottom: 75px;
            transition: 0.5s;
            background: rgba(21, 50, 84, 0.73);
        }
            
        .title {
            position: absolute;
            padding: 0 10%;
            bottom: 130px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 25px;
            transition: 0.5s;
            h2 {
                font-weight: bold;
                font-size: $font-size-30;
                color: #EEF4FA;
                text-transform: uppercase;
            }
        }
        &:hover {
            .overlay {
                height: 100%;
                bottom: 0;
                transition: 0.5s;
            }
        }
    }
    @media screen and (max-width: 1600px){
        .image-side {
            .title {
                h2 {
                    font-size: 1.35rem;
                }
            }
        }
        @media screen and (max-width: 1450px){
            .image-side {
                .title {
                    h2 {
                        font-size: 1.2rem;
                    }
                }
            }
            @media screen and (max-width: 1070px){
                .image-side {
                    .title {
                        h2 {
                            font-size: 1.1rem;
                        }
                    }
                }
                @media screen and (max-width: $size-sm-max){
                    .image-side {
                        .title {
                            bottom: 65px;
                            h2 {
                                br {
                                    display: none;
                                }
                            }
                        }
                        .overlay {
                            height: 100px;
                            bottom: 25px;
                        }
                    }
                    @media screen and (max-width: $size-xs-max){
                        flex-direction: column;
                        .image-side {
                            .title {
                                bottom: 88px;
                                padding: 0 5%;
                            }
                            .overlay {
                                bottom: 50px;
                            }
                            &.left {
                                margin-bottom: 5px;
                                .logo {
                                    left: 5%;
                                }
                            }
                            &.right .logo {
                                right: 0;
                                left: 5%;
                            }
                        }
                        @media screen and (max-width: 375px){
                            .image-side {
                                .title {
                                    h2 {
                                        font-size: 1rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-text-image {
    padding: 50px 5% 0 5%;
    .text-side {
        background: $color-1;
        width: 45%;
        margin-right: 15px;
        padding: 0 35px;
        h3 {
            font-weight: 600;
            font-size: $font-size-30;
            color: #EEF4FA;
            text-transform: initial;
            padding-bottom: 60px
        }
        p {
            color: #EEF4FA;
        }
    }
    .image-side {
        width: 55%;
        background: url("../images/accueil_section01_img01.jpg") no-repeat;
        background-size: cover;
    }
    @media screen and (max-width: 1200px){
        flex-direction: column-reverse;
        .image-side {
            width: 80%;
            margin: auto;
        }   
        .text-side {
            width: 80%;
            margin: auto;
            padding: 75px 35px;
            h3 {
                padding-bottom: 35px;
            }
        }
        @media screen and (max-width: $size-sm-max){
            .image-side, .text-side {
                width: 100%;
            }
            @media screen and (max-width: $size-xs-max){
                .text-side {
                    padding: 60px 25px;
                }
                .image-side {
                    background: url("../images/accueil_section01_img01_m.jpg") no-repeat;
                    background-size: cover;
                }
            }
        }
    }
}

#section-title-carousel {
    .title {
        padding: 150px 5%;
        h4 {
            font-weight: normal;
            font-size: $font-size-46;
            color: $color-3;
        }
    }
    .section-carousel {
        margin: 75px 0 100px 0;
        .main-content {
            position: relative;
            .item {
                .col-xs-12.txt {
                    padding-left: 0;
                    padding-right: 0; 
                    .text {
                        background: #EDEDED;
                        padding: 15px 20px;
                        h5 {
                            font-weight: normal;
                            font-size: $font-size-20;
                            color: $color-3;
                        }
                    }
                }
            }   
            .owl-nav {
                position: absolute;
                top: -12%;
                transform: translateY(-50%);
                z-index: 1;
                width: 100%;
                padding: 0 5%;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }   
        }
    }
    @media screen and (max-width: $size-md-max){
        .title {
            h4 {
                font-size: 2.1rem;
            }
        }
        @media screen and (max-width: 1024px){
            .title {
                h4 {
                    font-size: 1.8rem;
                }
            }
            @media screen and (max-width: $size-sm-max){
                .section-carousel {
                    .main-content {
                        .owl-nav {
                            top: -15%;
                        }
                    }
                }
                @media screen and (max-width: $size-xs-max){
                    .title {
                        padding: 100px 5% 150px 5%;
                        h4 {
                            text-align: center;
                            font-size: 1.6rem;
                            br {
                                display: none;
                            }
                        }
                    }
                    .section-carousel {
                        margin: 75px 5% 100px 5%;
                        .main-content .owl-nav {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}

#section-services {
    background: #EEF4FA;
    padding: 75px 5% 150px 5%;
    h3 {
        font-weight: normal;
        font-size: $font-size-60;
        color: $color-3;
        text-transform: initial;
        padding-bottom: 55px;
    }
    .section-bloc {
        .bloc {
            background: $color-1;
            width: 311px;
            height: 178px;
            padding-left: 40px;
            margin-right: 20px;
            h2 {
                font-weight: bold;
                font-size: $font-size-30;
                color: $color-2;
                padding-bottom: 8px;
                text-transform: uppercase;
            }
            p {
                color: $color-2;
                line-height: 1.3;
            }
        }
        a {
            height: 178px;
            align-items: end;
            justify-content: right;
            width: 23%;
            font-weight: normal;
            font-size: 18px;
            color: $color-3;
            text-decoration: underline !important;
        }
    }
    @media screen and (max-width: 1500px){
        .section-bloc {
            .bloc {
                padding-left: 25px;
            }
            a {
                width: 15%;
            }
        }
        @media screen and (max-width: 1350px){
            .section-bloc {
                .bloc {
                    p {
                        font-size: 16px;
                    }
                }
                a {
                    width: 20%;
                }
            }
            @media screen and (max-width: 1200px){
                padding: 75px 5%;
                .section-bloc {
                    flex-wrap: wrap;
                    .bloc {
                        width: 49%;
                        margin-bottom: 20px;
                        &:nth-child(even) {
                            margin-right: 0;
                        }
                    }
                    a {
                        height: initial;
                        width: 100%;
                        padding-top: 50px;
                    }
                }
                @media screen and (max-width: 1110px){
                    .section-bloc {
                        .bloc {
                            margin-right: 15px;
                            margin-bottom: 15px;
                        }
                    }
                    @media screen and (max-width: $size-sm-max){
                        .section-bloc {
                            justify-content: center;
                            .bloc {
                                width: 48%;
                            }
                        }
                        @media screen and (max-width: $size-xs-max){
                            h3 {
                                font-size: 2.2rem;
                            }
                            .section-bloc {
                                .bloc {
                                    width: 100%;
                                    margin: 0 0 15px 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-logo-title {
    padding: 75px 5%;
    h4 {
        font-weight: 600;
        font-size: $font-size-26;
        color: $color-3;
        border-bottom: 1px solid #707070;
        padding-bottom: 22px;
        margin-bottom: 75px;
    }
    @media screen and (max-width: $size-sm-max){
        h4 {
            font-size: 1.5rem;
        }
        .section-logo.bottom {
            flex-wrap: wrap;
            .logo {
                width: 22%;
                padding-bottom: 10px;
            }
        }
        @media screen and (max-width: $size-xs-max){
            .section-logo.top {
                flex-wrap: wrap;
                margin: 0;
                .logo {
                    width: 50%;
                    margin: 0;
                    padding: 0 0 15px 0;
                }
            }
            .section-logo.bottom {
                .logo {
                    width: 50%;
                    margin: 0;
                }
            }
        }
    }
}

/*******************************************************************************
* SERVICES RÉSIDENTIEL
*******************************************************************************/

#title-page {
    background: $color-white;
    padding: 30px 5%;
    margin-top: -75px;
    position: relative;
    width: 45%;
    h2 {
        font-weight: 600;
        font-size: $font-size-40;
        color: $color-1;
    }
    @media screen and (max-width: 1400px){
        h2 {
            font-size: 1.7rem;
        }
        @media screen and (max-width: 1080px){
            h2 {
                font-size: 1.5rem;
            }
            @media screen and (max-width: $size-sm-max){
                width: 50%;
                @media screen and (max-width: $size-xs-max){
                    width: 85%;
                    h2 {
                        font-size: 1.45rem;
                    }
                }
            }
        }
    }
}

#section-background {
    background: #F3F3F3;
    padding: 45px 5%;
    .image-side {
        width: 55%;
        margin-right: 50px;
    }
    .text-side {
        width: 45%;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-3;
            padding-bottom: 25px;
            text-transform: initial;
        }
    }
    @media screen and (max-width: 1375px){
        .text-side {
            h3 {
                font-size: 1.3rem;
            }
            p {
                font-size: 16px;
            }
        }
        @media screen and (max-width: 1200px){
            flex-direction: column;
            .image-side {
                width: 80%;
                margin: 0 0 50px 0;
            }
            .text-side {
                width: 80%;
            }
            @media screen and (max-width: $size-sm-max){
                .image-side, .text-side {
                    width: 100%;
                }
                @media screen and (max-width: $size-xs-max){
                    .text-side {
                        h3 {
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }
    }
}


#section-bloc-image {
    padding: 90px 0 0 5%;
    position: relative;
    .left-side {
        width: 35%;
        margin-right: 15px;
        .contenu {
            background: #EDEDED;
            padding: 100px 50px 75px 35px;
            h4 {
                font-weight: bold;
                font-size: $font-size-30;
                padding-bottom: 20px;
                color: #231F20;
                line-height: 1.3;
            }
        }
    }
    .right-side {
        width: 65%;
        background: url("../images/services_residentiel_section02_img01.jpg") no-repeat;
        background-size: cover;
    }
    &::before {
        content: '';
        background: #F0F7F8;
        width: 38%;
        height: 82%;
        position: absolute;
        z-index: -1;
        left: 0;
        bottom: 0;
    }
    @media screen and (max-width: 1750px){
        .left-side {
            .contenu {
                padding: 75px 35px;
            }
        }
        @media screen and (max-width: 1230px){
            padding: 90px 5%;
            flex-direction: column-reverse;
            .left-side {
                width: 80%;
                margin: 0 auto;
            }
            .right-side {
                width: 80%;
                margin: auto;
            }
            &::before {
                display: none;
            }
            @media screen and (max-width: $size-sm-max){
                .left-side, .right-side {
                    width: 100%;
                }
                @media screen and (max-width: $size-xs-max){
                    .left-side .contenu {
                        padding: 75px 5%;
                    }
                    .right-side {
                        background: url("../images/services_residentiel_section02_img01_m.jpg") no-repeat;
                        background-size: cover;
                    }
                }
            }
        }
    }
}

#section-text-image.services {
    padding: 200px 0 130px 0;
    .text-side {
        background: #EDEDED;
        width: 45%;
        margin-right: 15px;
        padding: 0 5%;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-3;
            text-transform: initial;
            padding-bottom: 55px
        }
        p {
            color: #231F20;
        }
    }
    .image-side {
        width: 55%;
        background: url("../images/services_residentiel_section03_img01.jpg") no-repeat;
        background-size: cover;
    }
    @media screen and (max-width: $size-md-max){
        padding: 100px 5%;
        .text-side {
            padding: 75px 35px;
            h3 {
                padding-bottom: 35px;
            }
        }
        @media screen and (max-width: 1230px){
            padding: 0 5% 100px 5%;
            @media screen and (max-width: 1200px){
                flex-direction: column-reverse;
                .image-side {
                    width: 80%;
                    margin: auto;
                }   
                .text-side {
                    width: 80%;
                    margin: auto;
                }
                @media screen and (max-width: $size-sm-max){
                    .image-side, .text-side {
                        width: 100%;
                    }
                    @media screen and (max-width: $size-xs-max){
                        padding: 0 5% 75px 5%;
                        .text-side {
                            padding: 60px 25px;
                        }
                        .image-side {
                            background: url("../images/services_residentiel_section03_img01_m.jpg") no-repeat;
                            background-size: cover;
                        }
                    }
                }
            }
        }
    }
}

#section-services.services {
    @media screen and (max-width: $size-xs-max){
        h3 {
            font-size: 2.15rem;
        }
    }
}

#section-logo-title.services {
    h4 {
        margin-bottom: 35px;
    }
    .section-logo {
        justify-content: right;
    }
    @media screen and (max-width: $size-sm-max){
        .section-logo {
            justify-content: center;
            .logo {
                width: 18%;
                &.mr14 {
                    margin-right: 2.5rem;
                }
            }
        }
        @media screen and (max-width: $size-xs-max){
            .section-logo {
                flex-direction: column;
                .logo {
                    width: 100%;
                    &.mr14 {
                        margin: 0 0 15px 0;
                    }
                    .img-responsive {
                        margin: auto;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* INDUSTRIEL
*******************************************************************************/

#section-image-text {
    padding: 100px 0 0 5%;
    .image-side {
        width: 52%;
        background: url("../images/industriel_section01_img01.jpg") no-repeat;
        background-size: cover;
    }
    .text-side {
        width: 48%;
        background: #F3F3F3;
        padding: 50px 150px 50px 55px;
        margin-left: 15px;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #000407;
            text-transform: initial;
            padding-bottom: 25px; 
        }
        a.btn-link {
            width: 166px;
        }
    }
    @media screen and (max-width: 1750px){
        .text-side {
            h3 br {
                display: none;
            }
        }
        @media screen and (max-width: 1550px){
            .text-side {
                padding: 50px;
            }
            @media screen and (max-width: 1200px){
                flex-direction: column;
                padding: 75px 5%;
                .image-side {
                    width: 80%;
                    margin: auto;
                }
                .text-side {
                    padding: 50px;
                    width: 80%;
                    margin: 15px auto 0 auto;
                }
                @media screen and (max-width: $size-sm-max){
                    .image-side, .text-side {
                        width: 100%;
                    }
                    @media screen and (max-width: $size-xs-max){
                        .image-side {
                            background: url("../images/industriel_section01_img01_m.jpg") no-repeat;
                            background-size: cover;
                        }
                        .text-side {
                            padding: 50px 25px;
                        }
                    }
                }
            }
        }
    }
    &.second-industriel {
        padding: 150px 0 75px 0;
        .image-side {
            background: url(../images/industriel_section03_img01.jpg) no-repeat;
            background-size: cover;
        }
        .text-side {
            h3 {
                padding-bottom: 45px;
            }
        }
        @media screen and (max-width: 1200px){
            padding: 100px 5%;
            @media screen and (max-width: $size-xs-max){
                .image-side {
                    background: url("../images/industriel_section03_img01_m.jpg") no-repeat;
                    background-size: cover;
                }
            }
        }
    }
}

#section-text-image.industriel {
    padding: 150px 0 0 0;
    .text-side {
        background: #F3F3F3;
        padding: 50px 50px 50px 5%;
        h4 {
            font-weight: normal;
            font-size: $font-size-30;
            color: #000407;
            padding-bottom: 30px;
        }
        p {
            color: #000407;
        }
        a.btn-link {
            width: 218px;
        }
    }
    .image-side {
        background: url("../images/industriel_section02_img01.jpg") no-repeat;
        background-size: cover;
    }
    @media screen and (max-width: $size-md-max) {
        padding: 100px 0 0 0;
        @media screen and (max-width: 1200px) {
            padding: 50px 5% 0 5%;
            .text-side {
                padding: 50px;
            }
            @media screen and (max-width: $size-xs-max) {
                .image-side {
                    background: url("../images/industriel_section02_img01_m.jpg") no-repeat;
                    background-size: cover;
                }
                .text-side {
                    padding: 50px 25px;
                }
            }
        }
    }   
}

#section-logo-title.industriel {
    @media screen and (max-width: $size-sm-max){
        padding: 0 5% 75px 5%;
        h4 {
            font-size: 1.5rem;
        }
        .section-logo {
            flex-wrap: wrap;
            .logo {
                width: 30%;
                padding-bottom: 10px;
            }
        }
        @media screen and (max-width: $size-xs-max){
            .section-logo {
                flex-wrap: wrap;
                margin: 0;
                .logo {
                    width: 50%;
                    margin: 0;
                    padding: 0 0 15px 0;
                }
            }
        }
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#section-realisations {
    padding: 75px 5%;
    h3 {
        font-weight: 600;
        font-size: $font-size-26;
        color: $color-3;
        padding-bottom: 50px;
    }
    .realisations {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 15px;
        a.item {
            .img-responsive {
                width: 100%;
            }
        }
        @media screen and (max-width: $size-md-max){
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 25px;
            @media screen and (max-width: $size-xs-max){
                grid-template-columns: repeat(1, 1fr);     
            }
        }
    }
    #blocIntro_promotions {
        margin-top: 100px;
        h3 {
            color: $color-3;
            line-height: 1.3;
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    padding: 60px 5% 0 5%;
    .left-side {
        width: 40%;
        margin-right: 70px;
    }
    .right-side {
        width: 60%;
        .bloc{
            &.first {
                margin-right: 170px;
            }
            &.second {
                margin-right: 85px;
            }
        }
    }
    h4 {
        font-weight: 500;
        font-size: $font-size-20;
        color: $color-3;
        padding-bottom: 25px;
    }
    p, h5, a {
        font-weight: normal;
        font-size: 16px;
        color: #777475;
    }
    @media screen and (max-width: 1600px){
        .right-side {
            .bloc{
                &.first {
                    margin-right: 125px;
                }
            }
        }
        @media screen and (max-width: 1440px){
            .left-side {
                p br {
                    display: none;
                }
            }
            .right-side {
                .bloc{
                    &.first {
                        margin-right: 100px;
                    }
                }
            }
            @media screen and (max-width: 1355px){
                .right-side {
                    .bloc{
                        &.first {
                            margin-right: 74px;
                        }
                    }
                }
                @media screen and (max-width: $size-md-max){
                    flex-direction: column;
                    .left-side {
                        width: 100%;
                        margin: 0 0 50px 0;
                    }
                    .right-side {
                        width: 100%;
                        .bloc{
                            &.first, &.second {
                                margin-right: 100px;
                            }
                        }
                    }
                    @media screen and (max-width: $size-sm-max){
                        .right-side {
                            .bloc{
                                &.first, &.second {
                                    margin-right: 60px;
                                }
                            }
                        }
                        @media screen and (max-width: $size-xs-max){
                            h4 {
                                font-size: 1.3rem;
                            }
                            .right-side {
                                flex-direction: column;
                                h4 {
                                    padding-bottom: 10px;
                                }
                                .bloc {
                                    &.first {
                                        margin-right: 0;
                                    }
                                    &.second {
                                        margin: 35px 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-heures-map {
    border-top: 1px solid $color-1;
    border-bottom: 1px solid $color-1;
    margin: 65px 5% 0 5%;
    padding: 50px 0;
    .section-heures {
        width: 40%;
        margin-right: 100px;
        h3 {
            font-weight: 500;
            font-size: $font-size-20;
            color: #010306;
        }
        .jours-heures {
            padding-top: 40px;
            h4, h5 {
                font-weight: 500;
                font-size: 16px;
                color: #010306;
                line-height: 3;
            }
            h5 {
                font-weight: normal;
            }
            .jour {
                width: 65%;
            }
        }
    }
    .blocMap {
        width: 60%;
    }
    @media screen and (max-width: 1235px){
        .section-heures {
            margin-right: 75px;
            .jours-heures .jour {
                width: 50%;
            }
        }
        @media screen and (max-width: $size-sm-max){
            flex-direction: column;
            .section-heures {
                width: 100%;
                margin: 0 0 50px 0;
                h3 {
                    font-size: 1.3rem;
                }
                .jours-heures .jour {
                    width: 40%;
                }
            }
            .blocMap {
                width: 100%;
            }
        }
    }
}

#section-text-urgence {
    background: $color-1;
    width: max-content;
    margin: 0 5% 40px 5%;
    padding: 25px 30px;
    h2 {
        font-weight: 500;
        font-size: $font-size-20;
        color: $color-2;
    }
}

#content {
    h3 {
        color: $color-2;
    }
    p a {
        color: $color-1;
    }
}




/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
    

}

@media screen and (max-width: $size-sm-max) {
    .popup-box img {
      width: 100% !important;
    }
}

@media screen and (max-width: $size-xs-max) {
    

}
