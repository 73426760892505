$input_height: 50px;
$input_back_color: $bg-color-body;
$input_text_color: $text-color;
$input_text_size: $font-size-20;

$input_placeholder_color: $color-text-gris;
$input_placeholder_size: $font-size-18;

$input_label_color: $text-muted;
$input_label_size: $font-size-18;

$input_border_color: $color-primary;
$input_border_radius: $round-corner-radius; //based on variable
$input_border_size: 1px;
$input_border_style: solid;

$label_checkbox_border_color: $text-muted;
$input_checked_color: $color-primary;

$textarea_height: 200px;

$message_errors_text_color: $color-error;
$message_errors_border_color: $color-error;
$message_errors_border_size: 5px;
$message_errors_border_radius: 5px;
$message_errors_background_color: hsla(0, 100%, 55%, 0.7);
$message_success_text_color: $color-success;
$message_success_border_color: lighten($color-success, 20%);
$message_success_border_size: 5px;
$message_success_border_radius: 5px;
$message_success_background_color: $bg-color-body;

form {
    input.form-control, textarea.form-control {
        height: $input_height;
        color: $input_text_color;
        font-size: $input_text_size;
        background-color: $input_back_color;
        border: $input_border_size $input_border_style $input_border_color;
        border-radius: $input_border_radius;
    }
    input::placeholder, textarea::placeholder {
        font-size: $input_placeholder_size;
        color: $input_placeholder_color;
    }
    label {
        color: $input_label_color;
        font-size: $input_label_size;
    }
    .title {
        text-transform: uppercase;
        color: $input_text_color;
        font-weight: bold;
        margin-top: 1em;
    }
    .center {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

//// checkboxes and radiobuttons style ///////


    .checkbox , .radio {
        display: flex;
        cursor: pointer;
        width: 100%;
        & > span {
            color: #024b94;
            padding: 0.5rem 0.25rem;
            margin-right: auto;
        }
        & >  input {
          height: 25px;
          width: 25px;
          -webkit-appearance: none;
          -moz-appearance: none;
          -o-appearance: none;
          appearance: none;
          outline: none;
          transition-duration: 0.3s;
          background-color: $input_back_color;
          cursor: pointer;
          position: inherit!important;
        }
        & > input:checked {
          border: 3px solid $input_back_color;
          background-color: #024b94;
        }
    }

    .radio > input {
      border-radius: 50%;
    }

//// End checkboxes and radiobuttons style///////

    textarea {
        //margin: 2em 0;
        height: $textarea_height !important;
    }
    .buttons {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: $input_height 0;
    }

}

.has-error {
    .form-control {
        color: $message_errors_text_color;
    }
}
.help-block {
    min-height: 15px;
}
.with-errors {
    li {
        line-height: 1;
    }
}



.messages {
    padding: 30px;
    margin-bottom: 2em;
    &.errors, &.success {
        p {
            font-size: $font-size-20;
            line-height: 1;
            margin: 0
        }
    }
    &.errors {
        background-color: $message_errors_background_color;
        border-radius: $message_errors_border_radius;
        border: $message_errors_border_size solid $message_errors_border_color;
        color: $message_errors_text_color;
        p {
            color: $message_errors_text_color;
        }
    }
    &.success {
        background-color: $message_success_background_color;
        border-radius: $message_success_border_radius;
        border: $message_success_border_size solid $message_success_border_color;
        color: $message_success_text_color;
        p {
            color: $message_success_text_color;
        }
    }
}
