footer {
    min-height: $size-footer-height;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: 100%;
        padding: 0 5%;
        @extend .flex;
        @extend .flex-wrap;
        @extend .items-center;
        @extend .justify-between;
        @extend .text-color-grey;
        @extend .font-footer;

        .left-side {
            .logo {
                margin-bottom: 25px;
            }
            .copyright {
                font-weight: normal;
                font-size: 14px;
                color: #EDEDED;
            }
        }

        .center-side {
            width: 45%;
            .section-menu {
                .left {
                    margin-right: 150px;
                }
                .right {
                    margin-right: 150px;
                }
            }
            a.link {
                font-weight: normal;
                font-size: 14px;
                color: #EDEDED;
                text-transform: uppercase;
                transition: 0.3s;
                &:hover {
                    color: $color-1;
                    transition: 0.3s;
                }
            }
        }
        @media screen and (max-width: 1650px){
            .center-side {
                width: 50%;
            }
            @media screen and (max-width: 1500px){
                .center-side {
                    .section-menu {
                        .left, .right {
                            margin-right: 115px;
                        }
                    }
                }
                @media screen and (max-width: $size-md-max){
                    .center-side {
                        width: 55%;
                        .section-menu {
                            .left, .right {
                                margin-right: 100px;
                            }
                        }
                    }
                    @media screen and (max-width: 1200px){
                        flex-direction: column;
                        align-items: initial;
                        .center-side {
                            width: 100%;
                            margin: 50px 0;
                        }
                        @media screen and (max-width: $size-xs-max){
                            .center-side {
                                flex-direction: column;
                                margin: 50px 0 25px 0;
                                .section-menu {
                                    flex-direction: column;
                                    .left, .right {
                                        margin-right: 0;
                                    }
                                    .left {
                                        margin-bottom: 1.25rem;
                                    }
                                }
                                .mediaweb {
                                    margin-top: 35px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1200px){
        min-height: 400px;
        @media screen and (max-width: $size-xs-max){
            min-height: 475px;
        }
    }
}
